(function ($) {
  var $doc = $(document);
  var $win = $(window);

  $(function () {
    // Lazy Load
    var lazyLoadInstance = new LazyLoad({
      elements_selector: ".lazy-load",
    });

    function load_images() {
      lazyLoadInstance.update();
    }

    var timeline_swiper = new Swiper(".timeline-slider", {
      loop: true,
      slidesPerView: 1.25,
      navigation: {
        nextEl: ".btn-timline-next",
      },
      breakpoints: {
        768: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
        1024: {
          slidesPerView: 3.5,
          spaceBetween: 5,
        },
        1400: {
          slidesPerView: 5.5,
          spaceBetween: 200,
        },
      },
      on: {
        slideChange: load_images,
        init: load_images,
      },
    });

    var vessel_swiper_thumbnails = new Swiper(".vessel-slider-thumbnails", {
      loop: true,
      spaceBetween: 10,
      slidesPerView: 5,
      freeMode: true,
      watchSlidesProgress: true,
      on: {
        slideChange: load_images,
        init: load_images,
      },
    });

    var vessel_swiper = new Swiper(".vessel-slider", {
      loop: true,
      spaceBetween: 10,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: vessel_swiper_thumbnails,
      },
      on: {
        slideChange: load_images,
        init: load_images,
      },
    });

    // slider thumbnail hover state
    $(".vessel-slider-thumbnails .swiper-slide").each(function () {
      $(this).mouseenter(function (e) {
        e.preventDefault();
        $(this).find("img").css("opacity", "25%");
      });
      $(this).mouseleave(function (e) {
        e.preventDefault();
        $(this).find("img").css("opacity", "100%");
      });
    });

    makeSquare();
    makeRect();
    fix_ie_svg_height();
    check_if_in_view();

    // Toggle Mobile Menu
    $(".menuToggle").on("click", function (e) {
      e.preventDefault();
      $("html").toggleClass("menu--open");
    });

    // special toggle for menu links
    $("#site-menu .nav-link").on("click", function (e) {
      $("html").toggleClass("menu--open");
    });

    // Make all external links open in new tab
    $("a")
      .filter('[href^="http"], [href^="//"]')
      .not('[href*="' + window.location.host + '"]')
      .attr("rel", "noopener noreferrer")
      .attr("target", "_blank");

    $(".wpforms-field-large")
      .focus(function () {
        $(this).closest(".wpforms-field").addClass("is-focused");
      })
      .on("blur input", function () {
        if ($(this).val().length) {
          $(this).closest(".wpforms-field").addClass("is-filled");
        } else {
          $(this).closest(".wpforms-field").removeClass("is-filled");
        }
        $(this).closest(".wpforms-field").removeClass("is-focused");
      });
    
    $(".section-top").ripples({
      perturbance: 0.003,
      resolution: 800,
    });

    $(".fullpage").fullpage({
      anchors: [
        "top",
        "mission",
        "history",
        "critical-path",
        "quote",
        "more-information",
        "join",
        "insights",
      ],
      //Custom selectors
      sectionSelector: ".section",
      slideSelector: ".full-slide",
      css3: false,
      fitToSection: false,
      lazyLoading: false,
      slidesNavigation: true,
      slidesNavPosition: "bottom",
      fixedElements: "#wpadminbar, .contact-us-container, .modal, #site-menu",

      menu: "#primary-menu",

      //waterEffect: "slides",
      //waterEffectOptions: { animateContent: true, animateOnMouseMove: true },
      //waterEffectKey: "am9pbmV2ZXJncmVlbm5vdy5jb21fZGRmZDJGMFpYSkZabVpsWTNRPU1wZA==",

      //dropEffectKey: "am9pbmV2ZXJncmVlbm5vdy5jb21fQWRkWkhKdmNFVm1abVZqZEE9PThueA==",
      //dropEffect: "slides",
      //dropEffectOptions: { speed: 2300, color: "#193787", zIndex: 9999 },

      //options here
      autoScrolling: false,
      scrollHorizontally: false,
      scrollOverflow: true,
      //bigSectionsDestination: "top",

      afterLoad: function (anchorLink, index) {
        //load_images();
        if (anchorLink == "top") {
          $("body").addClass("is-top");
        }
      },
      afterRender: function () {
        //console.log('afterrender');
      },
    });

  });

  

  // debounce scroll events as described here: https://www.html5rocks.com/en/tutorials/speed/animations/#debouncing-scroll-events
  var latestKnownScrollY = 0,
    ticking = false;

  function onScroll() {
    latestKnownScrollY = window.scrollY;
    requestTick();
  }

  function requestTick() {
    if (!ticking) {
      requestAnimationFrame(update);
    }
    ticking = true;
  }

  function update() {
    // reset the tick so we can
    // capture the next onScroll
    ticking = false;
    var currentScrollY = latestKnownScrollY;

    var theta = currentScrollY / 500;
    $(".contact-text-path").css({ transform: "rotate(" + theta + "rad)" });

    var percent = currentScrollY / 100;
    $("#text-path").attr("startOffset", -percent * 40 + 1200);
  }

  $win.on("resize", function () {
    makeSquare();
    fix_ie_svg_height();
    check_if_in_view();
  });

  window.addEventListener("load", function () {
    //new Parallax();
  });

  window.addEventListener("scroll", function () {
    check_if_in_view();
  });

  window.addEventListener("scroll", onScroll, false);

  // add data-scroll-view to an element to pay attention to if it's in the viewport. It will add 'in-view' as a class to it when it is in view. To only add 'in-view' once and not remove it when it leaves the view port, also add data-only-once attribute.
  var check_if_in_view = debounce(function () {
    var window_height = $win.height();
    var window_top_position = $win.scrollTop();
    var window_bottom_position = window_top_position + window_height;
    $("[data-scroll-view]").each(function () {
      var $element = jQuery(this);
      var element_height = $element.outerHeight();
      var element_top_position = $element.offset().top;
      var element_bottom_position = element_top_position + element_height;
      //check to see if this current container is within viewport
      if (
        element_bottom_position >= window_top_position &&
        element_top_position <= window_bottom_position
      ) {
        $element.addClass("in-view");
      } else {
        if (!$element.data("only-once")) {
          $element.removeClass("in-view");
        }
      }
    });
  }, 100);

  function fix_ie_svg_height() {
    // check if IE - currentScript is supported in everything besides IE
    if (isIE()) {
      // if no height is specified, IE assigns svgs with a height of 150px automatically - so do the calculation for it
      var $svgs = $("svg");
      $svgs.each(function () {
        var t = $(this);
        if (!t.attr("height")) {
          // figure out the height
          // console.log('adjusting height');
          var svg = t[0];
          var box = svg.getBBox();
          var width = t.width();
          var new_height = Math.ceil(width * (box.height / box.width));
          t.attr("height", new_height);
        }
      });
    }
  }

  function isIE() {
    ua = navigator.userAgent;
    /* MSIE used to detect old browsers and Trident used to newer ones*/
    var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
    return is_ie;
  }

  function makeSquare() {
    var $squared = $(".d-block-square");
    jQuery.each($squared, function () {
      var $element = $(this);
      var elementWidth = $element.outerWidth();
      $element.css("height", elementWidth);
    });
  }

  function makeRect() {
    var $rect = $(".d-block-rect");
    jQuery.each($rect, function () {
      var $element = $(this);
      var elementWidth = $element.outerWidth() * 0.9;
      $element.css("height", elementWidth);
    });
  }

  // Returns a function, that, as long as it continues to be invoked, will not
  // be triggered. The function will be called after it stops being called for
  // N milliseconds. If `immediate` is passed, trigger the function on the
  // leading edge, instead of the trailing.
  function debounce(func, wait, immediate) {
    var timeout;
    return function () {
      var context = this,
        args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }
})(jQuery);
